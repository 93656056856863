.main-header{
  position: relative;
  height: 100%;
  max-height: 85px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px;
  z-index: 50;
  max-width: 100%;
  width: 100%;
  font-size: 1rem;
  background: #333333;
  .nav{
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding:1rem .5rem;
    justify-content: space-between;

    .brand{
      width: 200px;
      height: 100%;
      .brand-image{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .navLinkImage{
      position: relative;
      width: 20px;
      left: 0%;
      height: 20px;
      background-position: center;
      background-size: cover;
      mask-size: contain;
      mask-position: center;
      object-fit: cover;
      opacity: 1;
    }
    .navList{
      display: flex;
      align-items: center;
      height: 100%;
      gap: 25px;
      .navLink{
        position: relative;
        transition: .3s;
        display: flex;
        align-items: center;
        height: 100%;
        padding: .5rem;
        font-weight: 600;
        .navLinkImage{
          position: relative;
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 7.5px;
          transition: .5s ease-out;
        }
        .navItem{
          transition: .5s ease-out;
          background-image: linear-gradient(to right,#ffa6009b 50%,#ffa600 50%);
          background-size: 200%;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .navLink:hover{
        transition: .3s;
      }
      .navLink:hover .navItem{
        background-position: -100%;
      }
      .navLink:active{
        scale: .85;
      }
      .navLink.active .navItem{
        background-position: -100%;
      }
      .navLink.active.navLink:before{
        position: absolute;
        content: "";
        bottom: -5px;
        height: 10px;
        left: -5%;
        width:110%;
        border-color: #ffa600 transparent transparent transparent;
        border-width: 3px;
        border-style: solid;
        background: transparent;
        border-top-left-radius: 20px;
      }
      .navLink:before{
        transition: .5s;
        position: absolute;
        content: "";
        bottom: -5px;
        height: 10px;
        width: 0%;
        left: -5%;
        border-color: transparent transparent transparent transparent;
        border-width: 3px;
        border-style: solid;
        background: transparent;
        border-top-left-radius: 20px;
      }
      .navLink:hover.navLink:before{
        position: absolute;
        content: "";
        bottom: -5px;
        height: 10px;
        width:110%;
        border-color: #ffa600 transparent transparent transparent;
        border-width: 3px;
        border-style: solid;
        background: transparent;
        border-top-left-radius: 20px;
      }
    }

  }
}

.menu{
  display: none;
  transition: .2s;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #ffa600;
  filter: drop-shadow(2px 2px 1px rgba(0,0,0,.1));
}
.menu:active{
  scale: .8;
}

.rightMenu{
  position: absolute;
  display: none;
  transition: .3s ease-in-out;
  transform-origin: right;
  z-index: 9999;
  right: -50px;
  top:0;
  width: 0;
  padding: 1rem;
  min-height: 100vh;
  line-height: 1.6;
  color: #ffa60071;
  background-color: #333333;
  font-size: 1.4rem;
  .rightMenuList{
    width: 100%!important;
    height: 100%!important;
    font-weight: 700;
    .rightMenuItem{
      transition: .3s;
      padding: 1rem;
    }
    .rightMenuItem.active{
      color: #ffa600;
    }
    .rightMenuItem:hover{
      color: #ff9d00;
    }
  }
  .menu{
    top: 0;
    right: 0;
    margin-left:auto;
    margin-bottom: 1rem;
  }
}
.rightMenu.active{
  transition: .3s;
  width: 300px;
  right: 0;
}