#models{
  display: grid;
  padding: 2rem;
  grid-template-columns: repeat(2,1fr);
  gap: 25px;
  height: 100%;
  margin-bottom: 2rem;
  .model-image{
    transition: .16s ease-out;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    overflow: hidden;
    width: 100%;
    min-height: 450px;
    max-height: 450px;
    filter: drop-shadow(0 0 2px rgba(0,0,0,.3));
    .image{
      transition:.4s ease-out;
      cursor: pointer;
      scale: 1.05;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text{
      position: absolute;
      text-align: center;
      transition: .2s ease-out;
      color: #fff;      
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 6;
      pointer-events: none;
      opacity: 0;
      font-family: 'Red Rose',sans-serif;
      .titlee{
        font-weight: bold;
        color: #fff;
        font-size: 1.6rem;
        text-shadow: 1px 1px 1px rgba(255, 251, 242, 0.675);
      }
      .textt{
        font-weight: 500;
        font-size: 1.6rem;
        color: #fffbf2;
      }
    }
    .mobilCard{
      position: absolute;
      transition: .2s ease-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      text-align: start;
      width: 96%;
      height: 50px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      bottom: 0;
      background-color: #454545;
      color: #ffffffb6;
      .titlee{
        text-align: start;
        margin: 0;
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
    .mobilCardLink{
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      .icon{
        margin-left: 1rem;
        width: 20px;
      }
    }
    .title{
      position: absolute;
      left: 50%;
      transform: translate(-50%,0%);
      font-weight: bold;
      font-size: 3rem;
      z-index: 10;
    }
  }
  .model-image:active{
    scale:.95
  }
  .model-image:hover .image{
    scale: 1;
  }
  .model-image:hover .text{
    font-size: 2rem;
    opacity: 1;
  }
  .model-image:after{
    transition: .2s ease-out;
    position: absolute;
    content: "";
    font-size: 3rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.0);
  }
  .model-image:hover.model-image:after{
    transition: .2s ease-out;
    position: absolute;
    content: "";
    font-size: 3rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
  }
}