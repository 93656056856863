@media (min-width:199px) and (max-width:399px){
  /* ======= models ======= */
    #models .model-image{
      justify-content: start;
      min-height: 250px!important;
      max-height: 250px!important;
      height: 100%;
      grid-column: span 2 / span 2 ;
    }
    #models .model-image .image{
      width: 100%;
      height: 100%;
      object-fit: cover;
      scale: 1!important;
    }
    #models{
      gap: 2rem!important;
      padding: 1rem;
    }
    #models .titlee{
      font-weight: bold;
      color: #fffaf1;
      font-size: .9rem!important;
      text-shadow: 0px 0px 0px rgba(0, 0, 0, .2);
    }
    #models .textt{
      font-weight: 500;
      font-size: .9rem!important;
      color: #fffbf2;
    }
    #models .image{
      width: 100%!important;
      height: 100%!important;
    }
    #models .titlee{
      font-size: .8rem!important;
    }
    #models .mobilCard{
      height: 45px!important;
      border-radius: 0!important;
      left: 50%;
      transform: translate(-50%);
      width: 100%!important;
      height: 60px!important;
      background-color: #333333e7!important;
    }
    #models .mobilCard .titlee{
      font-size: 1.1rem!important;
    }
    #models .model-image:after{
      display: none!important;
      transition: .2s ease-out;
      position: absolute;
      content: "";
      font-size: 0rem;
      top: 0;
      left: 0;
      width: 0%;
      height: 0%;
      background: rgba(0,0,0,0);
    }
    #models .mobilCardLink{
      font-size: 1rem!important;
    }
    #models .model-image:hover .text{
      display: none;
    }
  /* ======= models ======= */

  /* ======= main-header ======= */
    .main-header .rightMenu.active{
      width: 100%!important;
    }
    .main-header .rightMenu{
      line-height: 2;
    }
  /* ======= main-header ======= */

  /* ======= recommend ======= */
    #recommend .text{
      display: none!important;
    }
    #recommend .title{
      display: none!important;
    }
    #recommend .view{
      width: 175px;
      margin:0 auto;
      letter-spacing: 2px;
      border-radius: 10px;
      opacity: .85;
      color: #fff;
      font-size: 1.3rem!important;
      filter: drop-shadow(0 0 5px rgba(0,0,0,.2))!important;
    }
  /* ======= recommend ======= */

  /* ======= gallery ======= */
    #gallery{
      display: flex!important;
      flex-direction: column;
      padding:1rem;
      padding-top: 5.5rem;
      gap: 2rem;
    }
    #gallery .image{
      scale: 1!important;
    }
    #gallery .backPageLink{
      top: 75px;
      right: 20px;
    }
    #gallery .backPageLink .item{
      top: 75px;
      font-size: 1.5rem!important;
    }
    #gallery .backPageLink .icon{
      width: 25px!important;
      height: 25px!important;
    }
    #gallery .gallery-card-details{
      bottom: 0!important;
      height: 65px!important;
      font-size: 1rem!important;
      background-color: #333333e7!important;
    }
  /* ======= gallery ======= */

  /* ======= overview ======= */
    .overview{
      display: flex!important;
      flex-direction: column;
      padding: 0;
      min-height: fit-content!important;
    }
    .overview .image{
      width: 100%;
      height: fit-content;
      max-height: 500px;
    }
    .overview .overview-image{
      max-height: 500px;
      scale: 1.025!important;
    }
    .overview .text{
      min-height: 300px;
      box-shadow: none;
    }
    .overview span{
      font-size: 1.7rem!important;
    }
    .overview p{
      font-size: 1rem!important;
      line-height: 1.8;
      margin-bottom: 5rem;
    }
    .overview .overview-info-box{
      min-height: 300px;
      scale: 1.025!important;
    }
  /* ======= overview ======= */

  /* ======= footer ======= */
    #footer{
      height: fit-content;
      padding:1.2rem!important;
    }
    #footer .footer-card{
      grid-template-columns: repeat(2,1fr);
      padding:0!important;
    }
    #footer .social-link{
      font-size: 1.5rem!important;
    }
    #footer .footer-image{
      width: 250px!important;
      margin: 0 auto;
      grid-column: span 2 / span 2;
    }
    #footer .footer-item{
      grid-column: span 2 / span 2;
    }
    #footer .footer-item .title{
      position: relative;
      font-size: 1.3rem!important;
      left: 0!important;
    }
    #footer .footer-item .text{
      font-size: 1.3rem;
      left: -22.5px!important;
    }
    #footer .footer-item .text:hover{
      left: 0!important;
    }
    #footer .social-link span{
      font-size: 1.1rem!important;
    }
    #footer .social-link:hover{
      left: 12.5px!important;
      color: #ffffff7b;
    }
    #footer .links .icon:nth-child(1),
    #footer .links .icon:nth-child(2),
    #footer .links .icon:nth-child(3){
      width: 40px!important;
      height: 40px!important;
      flex-shrink: 0!important;
    }
    #footer .footer-item:nth-child(3){
      display: flex!important;
      justify-content: start!important;
      text-align: start;
      align-items: start;
    }
    #footer .footer-item:nth-child(7){
      text-align: center;
    }
    #footer .footer-social{
      flex-direction: column;
      gap: 20px;
      align-items: start;
    }
    #footer .footer-social span{
      display: block;
      width: 100%;
    }
    #footer .footer-social a{
      width: 100%!important;
      flex-shrink: 0;
    }
  /* ======= footer ======= */

  /* ======= About =======*/
    .about .about-box-items{
      display: block!important;
    }
    .about .about-box{
      padding: 0;
      scale: 1;
    }
    .about .about-box-item{
      padding: .2!important;
    }
    .about .about-box-item span{
      font-size: 1.1rem!important;
      line-height: 2;
    }
    .about .about-box-item p{
      font-size: .9rem!important;
      line-height: 2;
    }
  /* ======= About =======*/

  /* ======= contact ======= */
    .contact .contact-item{
      grid-column: span 2 / span 2;
      height: 60vh;
    }
    .contact .barr{
      grid-column: span 2 / span 2!important;
      right: 15px;
      width: 100%;
    }
    .contact .box{
      width: 210px!important;
      height: 210px!important;
    }
    .contact .item{
      width: 100px!important;
      height: 100px!important;
      padding: 2rem!important; 
    }
    .contact .icon{
      width: 30px!important;
      height: 30px!important;
    }
    .contact .map{
      scale: .9;
    }
    .contact .xmark .itemm{
      width: 40px;
      height: 40px;
    }
  /* ======= contact ======= */
}
@media (min-width:400px) and (max-width:539px){
  /* ======= models ======= */
    #models{
      display: flex!important;
      flex-direction: column;
      gap: 20px;
      padding: 1rem!important;
      height: 100%;
    }
    #models .mobilCard{
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      bottom: 0;
      border-radius: 0!important;
      left: 0!important;
      width: 100%!important;
      height: 65px!important;
      background-color: #333333e7!important;
    }
    #models .model-image{
      min-height: 350px!important;
      max-height: 350px!important;
      padding: 0;
      height: 100%;
      grid-column: span 2 / span 2 ;
    }
    #models .model-image .image{
      width: 100%;
      height: 100%;
      object-fit: cover;
      scale: 1!important;
    }
    #models .model-image:after{
      display: none!important;
      transition: .2s ease-out;
      position: absolute;
      content: "";
      font-size: 0rem;
      top: 0;
      left: 0;
      width: 0%;
      height: 0%;
      background: rgba(0,0,0,0);
    }
    #models .model-image:hover .text{
      display: none;
    }
  /* ======= models ======= */

  /* ======= main-header ======= */
    .main-header .rightMenu.active{
      width: 220px!important;
    }
  /* ======= main-header ======= */

  /* ======= recommend ======= */
    #recommend .text{
      display: none!important;
    }
    #recommend .title{
      display: none!important;
    }
    #recommend .view{
      width: 175px;
      margin:0 auto;
      font-size: 1.3rem!important;
      letter-spacing: 2px;
      opacity: .85;
      border-radius: 10px;
      color: #fff;
      filter: drop-shadow(0 0 5px rgba(0,0,0,.2))!important;
    }
  /* ======= recommend ======= */

  /* ======= gallery ======= */
    #gallery{
      display: flex!important;
      flex-direction: column;
      padding:1rem;
      padding-top: 5.5rem;
      gap: 2rem;
    }
    #gallery .image{
      scale: 1!important;
    }
    #gallery .gallery-card{
      height: 325px!important;
    }
    #gallery .backPageLink{
      top: 75px;
      right: 25px;
    }
    #gallery .backPageLink .item{
      top: 75px;
      font-size: 1.5rem!important;
    }
    #gallery .backPageLink .icon{
      width: 25px!important;
      height: 25px!important;
    }
    #gallery .gallery-card-details{
      height: 65px!important;
      bottom: 0!important;
      font-size: 1.2rem!important;
      color: #ffffffd9!important;
      background-color: #333333e7!important;
    }
  /* ======= gallery ======= */

  /* ======= overview ======= */
    .overview{
      display: flex!important;
      flex-direction: column;
      padding: 0;
      min-height: fit-content!important;
    }
    .overview .image{
      width: 100%;
      height: fit-content;
      max-height: 500px;
    }
    .overview .overview-image{
      max-height: 500px;
      scale: 1.025!important;
    }
    .overview .text{
      min-height: 300px;
      box-shadow: none;
    }
    .overview span{
      font-size: 2rem!important;
    }
    .overview p{
      font-size: 1.1rem!important;
      line-height: 1.8;
      margin-bottom: 2rem;
    }
    .overview .overview-info-box{
      min-height: 300px;
      scale: 1.025!important;
    }
  /* ======= overview ======= */

  /* ======= footer ======= */
    #footer{
    height: fit-content;
    }
    #footer .footer-card{
      grid-template-columns: repeat(2,1fr);
    }
    #footer .social-link{
      font-size: 1.5rem!important;
    }
    #footer .footer-image{
      width: 250px!important;
      margin: 0 auto;
      grid-column: span 2 / span 2;
    }
    #footer .footer-item{
      grid-column: span 2 / span 2;
    }
    #footer .footer-item .title{
      position: relative;
      font-size: 1.3rem!important;
      left: 0!important;
    }
    #footer .footer-item .text{
      font-size: 1.3rem;
      left: -22.5px!important;
    }
    #footer .footer-item .text:hover{
      left: 0!important;
    }
    #footer .social-link span{
      font-size: 1.1rem!important;
    }
    #footer .social-link:hover{
      left: 12.5px!important;
      color: #ffffff7b;
    }
    #footer .links .icon:nth-child(1),
    #footer .links .icon:nth-child(2),
    #footer .links .icon:nth-child(3){
      width: 40px!important;
      height: 40px!important;
      flex-shrink: 0!important;
    }
    #footer .footer-item:nth-child(3){
      display: flex!important;
      justify-content: start!important;
      text-align: start;
      align-items: start;
    }
    #footer .footer-social{
      flex-direction: column;
      gap: 20px;
      align-items: start;
    }
    #footer .footer-social span{
      display: block;
      width: 100%;
    }
    #footer .footer-social a{
      width: 100%!important;
      flex-shrink: 0;
    }
  /* ======= footer ======= */

  /* ======= About =======*/
    .about .about-box-items{
      display: block!important;
    }
    .about .about-box{
      padding: 1rem;
      scale: 1;
    }
  /* ======= About =======*/

  /* ======= contact ======= */
    .contact .contact-item{
      grid-column: span 2 / span 2;
      height: 60vh;
    }
    .contact .barr{
      grid-column: span 2 / span 2!important;
      right: 15px;
      width: 100%;
    }
    .contact .box{
      width: 310px!important;
      height: 310px!important;
    }
    .contact .item{
      width: 150px!important;
      height: 150px!important;
    }
    .contact .icon{
      width: 40px!important;
      height: 40px!important;
    }
    .contact .map{
      scale: .9;
    }
    .contact .xmark .itemm{
      width: 40px;
      height: 40px;
    }
  /* ======= contact ======= */
}
@media (min-width:540px) and (max-width:767px){
  /* ======= recommend ======= */
    #recommend .text{
      display: none!important;
    }
    #recommend .title{
      display: none!important;
    }
    #recommend .view{
      margin:0 auto;
      font-size: 1.3rem!important;
      letter-spacing: 2px;
      opacity: .85;
      border-radius: 10px;
      color: #fff;
      filter: drop-shadow(0 0 5px rgba(0,0,0,.2))!important;
    }
  /* ======= recommend ======= */

  /* ======= model ======= */
    #models .model-image{
      min-height:300px!important;
      max-height:300px!important;
    }
    #models .titlee{
      font-weight: bold;
      color: #fffaf1;
      font-size: 1.15rem!important;
      text-shadow: 1px 1px 1px rgba(255, 251, 242, 0.1);
    }
    #models .textt{
      font-weight: 500;
      font-size: 1.15rem!important;
      color: #fffbf2;
    }
  /* ======= model ======= */

  /* ======= gallery ======= */
    #gallery{
      grid-template-columns: repeat(2,1fr);
      padding: 1rem;
      padding-top: 5.5rem;
    }
    #gallery .gallery-card{
      height: 275px!important;
    }
    #gallery .gallery-card:active{
      transition: .3s ease-out;
      scale: .95;
    }
    #gallery .backPageLink{
      top: 75px;
      right: 25px;
    }
    #gallery .backPageLink .item{
      top: 75px;
      font-size: 1.5rem!important;
    }
    #gallery .backPageLink .icon{
      width: 25px!important;
      height: 25px!important;
    }
    #gallery .image{
      scale: 1!important;
    }
    #gallery .gallery-card-details{
      bottom: 0!important;
      font-size: 1rem!important;
      background-color: #333333e7!important;
    }
  /* ======= gallery ======= */

  /* ======= overview ======= */
   .overview{
    display: flex!important;
    flex-direction: column;
   }
   .overview .image{
    width: 100%;
    height: fit-content;
    max-height: 500px;
   }
   .overview .overview-image{
    max-height: 500px;
   }
   .overview .text{
    min-height: 300px;
    box-shadow: none;
   }
   .overview span{
    font-size: 2.2rem!important;
   }
   .overview p{
    font-size: 1.4rem!important;
    line-height: 1.8;
    margin-bottom: 2rem;
   }
   .overview .overview-info-box{
    min-height: 300px;
   }
  /* ======= overview ======= */

  /* ======= footer ======= */
    #footer{
    height: fit-content;
    }
    #footer .footer-card{
      grid-template-columns: repeat(2,1fr);
    }
    #footer .social-link{
      font-size: 1.5rem!important;
    }
    #footer .footer-image{
      width: 250px!important;
      margin: 0 auto;
      grid-column: span 2 / span 2;
    }
    #footer .footer-item{
      grid-column: span 2 / span 2;
    }
    #footer .footer-item .title{
      position: relative;
      font-size: 1.3rem!important;
      left: 0!important;
    }
    #footer .footer-item .text{
      font-size: 1.3rem;
      left: -22.5px!important;
    }
    #footer .footer-item .text:hover{
      left: 0!important;
    }
    #footer .social-link span{
      font-size: 1.1rem!important;
    }
    #footer .social-link:hover{
      left: 12.5px!important;
      color: #ffffff7b;
    }
    #footer .links .icon:nth-child(1),
    #footer .links .icon:nth-child(2),
    #footer .links .icon:nth-child(3){
      width: 40px!important;
      height: 40px!important;
      flex-shrink: 0!important;
    }
    #footer .footer-item:nth-child(3){
      display: flex!important;
      justify-content: start!important;
      text-align: start;
      align-items: start;
    }
  /* ======= footer ======= */

  /* ======= About =======*/
    .about .about-box-items{
      display: block!important;
    }
    .about .about-box{
      scale: .975;
    }
  /* ======= About =======*/

  /* ======= contact ======= */
    .contact .contact-item{
      grid-column: span 2 / span 2;
      height: 60vh;
    }
    .contact .barr{
      grid-column: span 2 / span 2!important;
      right: 15px;
      width: 100%;
    }
    .contact .box{
      width: 310px!important;
      height: 310px!important;
    }
    .contact .item{
      width: 150px!important;
      height: 150px!important;
    }
    .contact .icon{
      width: 40px!important;
      height: 40px!important;
    }
    .contact .map{
      scale: .9;
    }
  /* ======= contact ======= */
}
@media (min-width:768px) and (max-width:899px){
  /* ======= models ======= */
    #models .model-image{
      min-height:300px!important;
      max-height:300px!important;
    }
  /* ======= models ======= */

  /* ======= recommend ======= */
    #recommend .text{
      width:80%!important;
      font-size: 1.1rem!important;
      line-height: 1.7;
      font-weight: 600;
    }
    #recommend .swiper-slide{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  /* ======= recommend ======= */

  /* ======= gallery ======= */
    #gallery{
      grid-template-columns: repeat(2,1fr);
      padding: 2rem;
      padding-top: 5.5rem;
    }
    #gallery .backPageLink{
      top: 75px;
      right: 27.5px;
    }
    #gallery .backPageLink .item{
      top: 75px;
      font-size: 1.2rem!important;
    }
    #gallery .backPageLink .icon{
      width: 22.5px!important;
      height: 22.5px!important;
    }
  /* ======= gallery ======= */

  /* ======= overview ======= */
   .overview{
    display: flex!important;
    flex-direction: column;
   }
   .overview .image{
    width: 100%;
    height: fit-content;
    max-height: 500px;
   }
   .overview .overview-image{
    max-height: 500px;
   }
   .overview .text{
    min-height: 300px;
    box-shadow: none;
   }
   .overview span{
    font-size: 2.2rem!important;
   }
   .overview p{
    font-size: 1.4rem!important;
    line-height: 1.8;
   }
   .overview .overview-info-box{
    min-height: 300px;
   }
  /* ======= overview ======= */

  /* ======= footer ======= */
    #footer{
      height: fit-content;
    }
    #footer .footer-card{
      grid-template-columns: repeat(2,1fr);
    }
    #footer .social-link{
      font-size: 1.5rem!important;
    }
    #footer .footer-image{
      width: 250px!important;
      margin: 0 auto;
      grid-column: span 2 / span 2;
    }
    #footer .footer-item{
      grid-column: span 1 / span 1;
    }
    #footer .footer-item .title{
      position: relative;
      font-size: 1.3rem!important;
      left: 0!important;
    }
    #footer .footer-item .text{
      list-style-type: none!important;
      left: 0!important;
      font-size: 1.3rem;
    }
    #footer .footer-item .text:hover{
      left: 0!important;
    }
    #footer .social-link span{
      font-size: 1.1rem!important;
    }
    #footer .social-link:hover{
      left: 0!important;
      color: #ffffff7b;
    }
    #footer .links .icon:nth-child(1),
    #footer .links .icon:nth-child(2),
    #footer .links .icon:nth-child(3){
      width: 40px!important;
      height: 40px!important;
      flex-shrink: 0!important;
    }
    #footer .footer-item:nth-child(3){
      display: flex!important;
      justify-content: end!important;
      text-align: end;
      align-items: end;
    }
    #footer .footer-item:nth-child(6){
      grid-column: span 2 / span 2;
    }
    #footer .footer-item:nth-child(7){
      grid-column: span 2 / span 2!important;
    }
  /* ======= footer ======= */

  /* ======= contact ======= */
    .contact .icon{
      width: 35px!important;
      height: 35px!important;
    }
  /* ======= contact ======= */
}
@media (min-width:900px) and (max-width:1023px){
  /* ======= models ======= */
    #models .model-image .text{
      font-size: 2.1rem!important;
    }
  /* ======= models ======= */

  /* ======= recommend ======= */
    #recommend .swiper-slide{
      display: flex!important;
      flex-direction: column;
      align-items: start;
      font-weight: bold;
      pointer-events: none;
      color: #fff;
      width: 100%!important;
      height: 100%!important;
    }
    #recommend .title{
      font-size: 3.5rem!important;
      color: #ffaa00!important;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
    }
    #recommend .text{
      width:22.5%!important;
      font-size: 1.1rem!important;
      line-height: 1.7;
      font-weight: 600;
    }
    #recommend .view{
      background: #ffaa00!important;
      width: 150px;
    }
  /* ======= recommend ======= */

  /* ======= gallery ======= */
    #gallery{
      grid-template-columns: repeat(2,1fr);
      gap: 2.5rem;
    }
    #gallery .backPageLink{
      top: 75px;
      right: 2.8rem;
    }
  /* ======= gallery ======= */

  /* ======= overview ======= */
   
  /* ======= overview ======= */

  /* ======= footer ======= */
    #footer{
      height: fit-content;
    }
    #footer .footer-card{
      grid-template-columns: repeat(2,1fr);
    }
    #footer .social-link{
      font-size: 1.5rem!important;
    }
    #footer .footer-image{
      width: 250px!important;
      margin: 0 auto;
      grid-column: span 2 / span 2;
    }
    #footer .footer-item{
      grid-column: span 1 / span 1;
    }
    #footer .footer-item .title{
      position: relative;
      font-size: 1.3rem!important;
      left: 0!important;
    }
    #footer .footer-item .text{
      list-style-type: none!important;
      left: 0!important;
      font-size: 1.3rem;
    }
    #footer .footer-item .text:hover{
      left: 0!important;
    }
    #footer .social-link span{
      font-size: 1.1rem!important;
    }
    #footer .social-link:hover{
      left: 0!important;
      color: #ffffff7b;
    }
    #footer .links .icon:nth-child(1),
    #footer .links .icon:nth-child(2),
    #footer .links .icon:nth-child(3){
      width: 45px!important;
      height: 45px!important;
      flex-shrink: 0!important;
    }
    #footer .footer-item:nth-child(3){
      display: flex!important;
      justify-content: end!important;
      text-align: end;
      align-items: end;
    }
    #footer .footer-item:nth-child(6){
      grid-column: span 2 / span 2;
    }
    #footer .footer-item:nth-child(7){
      grid-column: span 2 / span 2!important;
    }
  /* ======= footer ======= */
  
  /* ======= contact ======= */
    .contact .icon{
      width: 35px!important;
      height: 35px!important;
    }
  /* ======= contact ======= */
}
@media (min-width:1024px) and (max-width:1268px){
  /* ======= recommend ======= */
    #recommend .text{
      width:22.5%!important;
      font-size: .9rem!important;
      line-height: 1.7;
      font-weight: 600;
      margin-left: 2rem;
    }
    #recommend .title{
      margin-left: 2rem;
    }
    #recommend .view{
      margin-left: 2rem;
    }
  /* ======= recommend ======= */

  /* ======= gallery ======= */
    #gallery .gallery-card{
      height: 225px!important;
      gap: 0;
    }
  /* ======= gallery ======= */
}
@media (max-width:1024px) and (max-width:1399px){
  /* ======= html ======= */
    html{
      font-size: 12px;
    }
  /* ======= html ======= */
}
@media (min-width:1600px) and (max-width:2640px){
  /* ======= recommend ======= */
    #recommend .text{
      width:22.5%!important;
      font-size: 1.2rem;
      font-weight: 600;
      margin-left: 4rem;
      line-height: 1.3!important;
    }
    #recommend .title{
      font-size: 3.5rem;
    }
  /* ======= recommend ======= */

  /* ======= overview ======= */
  /* ======= overview ======= */
}


@media (min-width:540px) and (max-width:2640px){
  /* ======= models ======= */
    #models .mobilCard{
      display: none!important;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
    }
  /* ======= models ======= */
}
@media (min-width:99px) and (max-width:1023px){
  /* ======= main-header ======= */
      .main-header .navList{
        display: none!important;
      }
      .main-header .menu{
        display: block;
      }
      .main-header .rightMenu{
        display: block!important;
      }
  /* ======= main-header ======= */
}