.about{
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  .about-box{
    width: 100%;
    height: 100%;
    padding: 2rem;
    scale: .9;
    .about-box-item{
      padding: 2rem;
    }
    .about-box-item span{
      font-weight: 600;
      font-size: 1.2rem; 
    }
    .about-box-item p{
      font-size: 1rem;
      line-height: 2;
    }
  }
}