#gallery{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1.5rem;
  padding: 3rem;
  padding-top: 5rem;
  width: 100%;
  .gallery-card{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 300px;
    filter: drop-shadow(0 0 5px rgba(0,0,0,.2));
    top: 20px;
    .image{
      transition: .3s ease-out;
      width: 100%;
      height: 100%;
      border-top-right-radius: 2.5px;
      border-top-left-radius: 2.5px;
      scale:1.1;
      object-fit: cover;
    }
    .gallery-card-details{
      transition:.4s ease-out;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      bottom: -55px;
      height: 55px;
      width: 100%;
      font-size: .8rem;
      background-color: #333333;
      color:#fff;
      transform-origin: center;
      font-weight:bold;
      letter-spacing: 1px;
    }
  }
  .gallery-card:hover .image{
    scale: 1;
  }
  .gallery-card:hover .gallery-card-details{
    bottom: 0;
  }
  .backPageLink{
    position: absolute;
    right: 45px;
    top: 90px;
    width: fit-content;
    margin: 20px 0;
    .item{
      display: flex;
      align-items: center;
      text-decoration: underline;
      font-size: 1.1rem!important;
      font-weight: 700;
      color: #656565;
      .icon{
        width: 22.5px;
        height: 22.5px;
        margin-right: 10px;
      }
    }
  }
}
.null{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  letter-spacing: 1px;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
  .link{
    padding: .5rem;
    text-align: center;
    text-decoration: underline;
  }
}