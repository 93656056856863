.delivery{
  display: block;
  background: #a09e99;
  height: 150px;
  .row{
    margin: 0 auto;
    width: 100%;
    height: 100%;
    .delivery-card{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      padding: 2.5rem 1.8rem;
      height: 100%;
      width: 100%;
      height: 100%;
      color: #fff;
      .icon{
        width: 30%;
        height: 100%;
        .image{
          width: 100%;
          height: 100%;
        }
      }
      .col{
        text-align: center;
        margin-left: .5rem;
        .title{
          font-weight: bold;
          font-size: 1.2rem;
          margin-bottom: .2rem;
        }
        .text{
          font-size: .7rem;
          font-weight: 500;
        }
      }
    }
    .delivery-card:nth-child(2){
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }
}

/* 
sorunsuz teslimat
hızlı teslimat
güvenilir alışveriş 
*/