@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Gabarito:wght@400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lilita+One&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mooli&family=MuseoModerno:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Rose:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(../delivery.scss);
@import url(../gallery.scss);
@import url(../recommend.scss);
@import url(../contact.scss);
@import url(../footer.scss);
@import url(../about.scss);
@import url(../loading.scss);
@import url(../admin.scss);
@import url(../model.scss);
@import url(../header/main-header.scss);
@import url(./mobil.css);


.parent {
  scale: 1;
  display: flex;
  transform-origin: center;
  justify-content: center;
  align-items: center;
}
.parent .item{
  transition: .6s ease-out;
  scale: 0;
  transform-origin: center;
}
.parent .icon{
  scale: 0;
}
.parent[data-isOpen="true"]{
  scale: 1;
}
.parent[data-isOpen="true"] .item{
  scale: 1;
}
.parent[data-isOpen="true"] .icon{
  scale: 1;
}
.xmark{
  width: 100%!important;
  min-width: 40px!important;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html{
  scroll-behavior: smooth;
}


.col{
  display: flex;
  flex-direction: column;
}
.row{
  display: flex;
  flex-direction: row;
}
.start{align-items: start;}
.align{align-items: center;}
.end{align-items: end;}

.around{justify-content: space-around;}
.between{justify-content: space-between;}
.evenly{justify-content: space-evenly;}

#main{
  max-width: 100%;
  overflow: hidden;
}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}