.admin{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url("images/wood-sofa-afilli/bg.jpeg");
  background-size: cover;
  background-position: center;
  .login-card{
    transition: .2s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    height: 500px;
    backdrop-filter: blur(7.5px);
    border: 2px solid rgba(255, 255, 255,.2);
    background: #ffffff;
    border-radius: 25px;
    padding: 1rem;
    .brand{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .image{
        height: 50px;
        filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.144))
      }
      .text{
        font-weight:bolder;
        letter-spacing: 2px;
        color: #333333;
        font-size: 1.5rem;
        margin: 1rem 0;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.144);
      }
    }
    .col{
      padding: 0 1rem;
      color: #333333;
      font-weight: 600;
      .userLabel.active{
        top: 15px;
      }
      .passLabel.active{
        top: 15px;
      }
      .label{
        transition: .4s ease-out;
        position: relative;
        top: 33.5px;
        left: 12.5px;
        width: fit-content;
        padding: 1px 5px;
        background-color: #fff;
        border-radius: 5px;
        pointer-events: none;
        z-index: 10;
      }
      .input{
        padding: .5rem;
        border:2px solid #333333;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 1rem;
      }
    }
    .login{
      transition: .2s ease-out;
      width: 95%;
      padding: .8rem 0;
      font-size: 1.2rem;
      border-radius: 5px;
      font-family: 'Red Rose' sans-serif;
      margin: 2rem auto;
      font-weight: 600;
      background-color: #333333;
      border: 1px solid #fff;
      color: #fff;
      cursor: pointer;
    }
    .login:active{
      scale:0.9
    }
  }
  .login-card.active{
    opacity: 0;
  }
  .admin-panel{
    display: flex;
    flex-direction: column;
    transition: .2s ease-out;
    opacity: 0;
    border-radius: 25px;
    width: 400px;
    height: 550px;
    background: #fff;
    padding: 1rem;
    .tab{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 5px;
      margin-top: auto;
      .tab-item{
        transition: .3s ease-out;
        width: 100%;
        padding: 1rem;
        border: 1px solid #33333383;
        border-radius: 5px;
        font-weight: 600;
        color: #69696984;
      }
      .tab-item.active{
        color: #fff;
        background: #333333c4
      }
    }
    .label{
      width: 100%;
      color: #696969;
    }
    .title{
      transition: .3s ease-out;
      position: relative;
      width: 100%;
      flex-direction: column;
      padding: .5rem 1rem;
      border-radius: 5px;
      font-weight: 600;
      background: #fff;
      color: #333333;
      margin: 1rem 0;
      .label{
        position: absolute;
        transition: .3s ease-out;
        width: fit-content;
        top: 20px;
        left: 27.5px;
        z-index: 7;
        pointer-events: none;
        background: #fff;
        padding:0 .5rem;
        font-size: .9rem;
      }
      .label.active{
        top: -2.5px;
      }
      #text{
        width: 100%;
        height: 50px;
        padding: .4rem;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid #33333322;
        border-radius: 5px;
        cursor: pointer;
        font-size: .75rem;
      }
    }
    .titleSelected{
      transition: .3s ease-out;
      position: relative;
      width: 100%;
      padding: .5rem 1rem;
      border-radius: 5px;
      font-weight: 600;
      background: #fff;
      color: #333333;
      margin: 1rem 0;
      z-index: 10;
      .listt{
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid #33333322;
        border-radius: 5px;
        padding: .5rem;
        cursor: pointer;
        font-size: .8rem;
        .selectedItem{
          width: calc(100% - 100px)!important;
          flex-shrink: 0;
        }
        .list-items{
          display: flex;
          flex-direction: column;
          position: absolute;
          transition: .2s ease-out;
          top: 62.5px;
          width: 92.5%;
          left: 50%;
          font-size: 0;
          opacity: 0;
          height: 0;
          overflow: scroll;
          transform-origin: top;
          transform: translate(-50%,0%);
          border: 1px solid #33333322;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background: #fff;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          .item{
            padding: 1rem;
            color: #000!important;
            font-size: .8rem;
            width: 100%;
            background: #d8d8d879;
            border-bottom: 2px solid #fff;
            border-top: 2px solid #fff;
          }
        }
        .list-items.active{
          opacity: 1;
          font-size: .8rem;
          height: 110px;
        }
        .label{
          pointer-events: none;
        }
        .icon{
          transition: .3s ease-out;
          width: 20px;
          height: 20px;
          pointer-events: none;
          transform: rotateZ(-90deg);
          flex-shrink: 0;
        }
        .icon.active{
          transform: rotateZ(0deg);
        }
      }
    }
    .text{
      position: relative;
      align-items: start;
      flex-direction: column;
      color: #333333;
      background-color: #fff;
      width: 100%;
      font-size: 1rem;
      font-weight: 600;
      padding: .5rem 1rem;
      gap: 10px;
      .textarea{
        width: 100%;
        height: 75px;
        padding: .4rem;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid #33333322;
        border-radius: 5px;
        font-size: .75rem;
        cursor: pointer;
      }
      .model-label{
        position: absolute;
        transition: .3s ease-out;
        pointer-events: none;
        top: 20px;
        left: 27.5px;
        z-index: 7;
        width: fit-content;
        background: #fff;
        padding:0 .5rem;
        font-size: .9rem;
      }
      .model-label.active{
        top: -2.5px;
      }
    }
    .galleryText{
      position: relative;
      align-items: start;
      flex-direction: column;
      color: #333333;
      background-color: #fff;
      width: 100%;
      font-size: 1rem;
      font-weight: 600;
      padding: .5rem 1rem;
      gap: 10px;
      .textarea{
        width: 100%;
        height: 75px;
        padding: .4rem;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid #33333322;
        border-radius: 5px;
        font-size: .75rem;
        cursor: pointer;
      }
      .gallery-label{
        position: absolute;
        transition: .3s ease-out;
        pointer-events: none;
        top: 20px;
        left: 27.5px;
        z-index: 7;
        width: fit-content;
        background: #fff;
        padding:0 .5rem;
        font-size: .9rem;
      }
      .gallery-label.active{
        top: -2.5px;
      }
    }
    .btn{
      width: 90%;
      padding: .7rem;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin:.35rem auto;
      font-weight: 600;
      border: 1px solid #33333322;
    }
    .btn:active{
      scale:.95
    }
    .clear{
      color: #e11d48;
    }
    .add{
      color: #10b981;
    }
  }
  .admin-panel.active{
    opacity: 1;
  }
  .panel-image{
    position: relative;
    cursor: pointer;
    width: 90%;
    height: 125px!important;
    flex-shrink: 0;
    background: #fff;
    border-bottom: 1px solid #74747433;
    border-top: 1px solid #74747433;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: .8rem;
    color: #333333;
    #file{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    .lt50{
      position: absolute;
      pointer-events: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }

  .admin-panel-delete{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .2s ease-out;
    border-radius: 25px;
    width: 400px;
    height: 550px;
    background: #fff;
    padding: 1rem;
    .titleSelected{
      transition: .3s ease-out;
      position: relative;
      width: 100%;
      padding: .5rem 1rem;
      border-radius: 5px;
      font-weight: 600;
      background: #fff;
      color: #333333;
      margin: 1rem 0;
      z-index: 10;
      .listt{
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid #33333322;
        border-radius: 5px;
        padding: .5rem;
        cursor: pointer;
        font-size: .8rem;
        .selectedItem{
          width: calc(100% - 20px)!important;
          flex-shrink: 0;
        }
        .list-items{
          display: flex;
          flex-direction: column;
          position: absolute;
          transition: .2s ease-out;
          top: 62.5px;
          width: 92.5%;
          left: 50%;
          font-size: 0;
          opacity: 0;
          height: 0;
          overflow: scroll;
          transform-origin: top;
          transform: translate(-50%,0%);
          border: 1px solid #33333322;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background: #fff;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          .item{
            padding: 1rem;
            color: #000!important;
            font-size: .8rem;
            width: 100%;
            background: #d8d8d879;
            border-bottom: 2px solid #fff;
            border-top: 2px solid #fff;
          }
        }
        .list-items.active{
          opacity: 1;
          font-size: .8rem;
          height: 110px;
        }
      }
      .icon{
        transition: .3s ease-out;
        width: 20px;
        height: 20px;
        pointer-events: none;
        transform: rotateZ(-90deg);
        flex-shrink: 0;
      }
    }
    .btn{
      padding: 1rem;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    }
    .delete{
      color: #e11d48;
    }
    .tab{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      width: 100%;
      color: #fff;
      gap: 5px;
      font-weight: 600;
      .tab-item{
        width: 100%;
        padding: 1rem;
        border-radius: 5px;
        border: 1px solid #333333c4;
        color: #333333c4;
      }
      .tab-item.active{
        background-color: #333333c4;
        color: #fff;
      }
    }
  }
}
.admin:before{
  position: absolute;
  content:"";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.40);
}
.admin *{
  z-index: 1;
}