#footer{
  position: relative;
  width: 100%;
  height: 45vh;
  z-index: 5;
  background-color: #333333;
  .footer-card{
    padding: 2rem;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    margin: 0 auto;
    color: #ffa600;
    height: 100%;
    .footer-image{
      height: 100%;
      .image{
        width: 100%;
        cursor: pointer;
      }
    }

    .footer-item{
      width: 100%;
      .col{
        margin-top: .3rem;
        overflow:hidden;
      }
      .title{
        font-weight: bold;
        font-size: 1.1rem;
      }
      .link{
        width: fit-content;
      }
      .text{
        position: relative;
        transition: .4s ease-out;
        left: -25px;
        margin-top: .2rem;
        font-weight: 500;
        padding: .2rem;
        color: #fff;
      }
      .text:hover{
        left: 1px;
        color: #ffa600;
      }
    }
    .social{
      .gray{
        color: #fff;
      }
      .social-link{
        position: relative;
        transition: .3s ease-out;
        left: 0;;
      }
      .social-link a{
        cursor: pointer;
      }
      .social-link:hover{
        left: 10px;
      }
      .social-link span{
        position: relative;
        transition: .3s ease-out;
      }
      .social-link:hover span{
        color: #ffa600;
      }
      .row{
        cursor: default;
        align-items: center;
        .icon{
          scale: .9;
          transition: .2s ease-out;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
      .w5{
        font-weight: 500;
      }
    }
  }
}