.contact{
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  .contact-item{
    position: relative;
  }
  .contact-item.active.contact-item:before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(2px);
  }
  .box{
    transition: .6s ease-out;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 22.75rem;
    height: 22.75rem;
    border-radius: 50%;
    gap: 5px;
    transform-origin: center;
    .item{
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      transition: .3s ease-out;
      width: 11rem;
      height: 11rem;
      padding: 3.25rem;
      box-shadow: 0 0 5px rgba(0, 0, 0, .2);
      background: #FFF;
    }
    .item:hover{
      scale: 1.1;
      filter: blur(0px)!important;
    }
    .icon{
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    .item:nth-child(1){
      border-top-left-radius: 100%;
      transform-origin: bottom right;
      align-items: end;
      justify-content: end;
      margin-right: 5px;
    }
    .item:nth-child(1):hover{
      box-shadow: 0 0 10px -3px #333333;
    }
    .item:nth-child(2){
      border-top-right-radius: 100%;
      transform-origin: bottom left;
      align-items: end;
      justify-content: start;
      margin-left: 5px;
    }
    .item:nth-child(2):hover{
      box-shadow: 0 0 10px -3px #333333;
    }
    .item:nth-child(4){
      border-bottom-left-radius: 100%;
      transform-origin: top right;
      align-items: start;
      justify-content: end;
      margin-right: 5px;
    }
    .item:nth-child(4):hover{
      box-shadow: 0 0 10px -3px #333333;
    }
    .item:nth-child(5){
      border-bottom-right-radius: 100%;
      transform-origin: top left;
      align-items: start;
      justify-content: start;
      margin-left: 5px;
    }
    .item:nth-child(5):hover{
      box-shadow: 0 0 10px -3px #333333;
    }
  }
  .box.active .item:nth-child(1){
    transform-origin: bottom right;
  }
  .box.active .item:nth-child(2){
    transform-origin: bottom left;
  }
  .box.active .item:nth-child(4){
    transform-origin: top right;
  }
  .box.active .item:nth-child(5){
    transform-origin: top left;
  }
  .box:hover .item{
    filter: blur(2px);
  }
  .contact-bg{
    background: url(images/wood-sofa-han/1.jpg);
    background-size: cover;
    background-position: center;
  }

  .xmark{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0;
    cursor: pointer;
    color: #fff;
    z-index: 5;
    .itemm{
      background-color: #000;
      border-radius: 10px;
      padding: .5rem;
      width: 50px;
      height: 50px;
      z-index: 5;
    }
  }
  .barr{
    position: absolute;
    top: 35px;
    right: 35px;
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 0;
    cursor: pointer;
    color: #fff;
    z-index: 5;
    .itemm{
      transition: .3s ease-out;
      background-color: #000000b5;
      border-radius: 10px;
      padding: .5rem;
      width: 40px;
      height: 40px;
      z-index: 5;
    }
    .itemm:hover{
      scale: 1.1;
    }
  }
}