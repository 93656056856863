#recommend{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 81px);
  .mainSlider{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .mySwiper{
    width: 100%;
    height: 100%;
  }
  .swiper{
  pointer-events: none;
  }
  .swiper-slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .image{
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
  }
  .swiper-pagination-bullet{
    width: 12px!important;
    height: 12px!important;
    margin:0 10px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    background-color: #fff;
  }
}
.swiperr{
  position: absolute!important;
  font-weight: bold;
  pointer-events: none;
  color: #fff;
  width: 100%!important;
  height: 100%!important;
  .title{
    margin-left: 2rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: #ffaa00;
  }
  .text{
    width: 20%;
    max-height: 400px;
    font-size: 1rem;
    margin-left: 2rem;
    font-weight: 600;
    word-spacing: 2.5px;
    line-height: 1.6;
  }
}
.swiper-wrapper{
  position: relative;
}
.swiper:before{
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(transparent,rgba(0,0,0,025));
}
.swiper-wrapper:before *{
  z-index: 555;
}
.view{
  width: 150px;
  pointer-events: painted;
  height: 50px;
  border-radius: 2.5px;
  background-color: #ffaa00;
  filter: drop-shadow(3px 3px 5px rgba(0,0,0,.3));
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  margin-top: 3rem;
}
.view:active{
  scale:.95;
}